/**
 * Navigation show/hode scroll functionality.
 */

import Headroom from 'headroom.js';
import { sitecoreUtils } from 'foundation-theming';
import customVhProperty from '@valtech/utilities';

const bodyScrollLock = require('body-scroll-lock');

// get current SiteCore modus
const scModus = sitecoreUtils.getSitecoreMode();

// Required id's
const idNames = {
  bootstrap: {
    collapse: {
      menu: 'js-collapse-menu',
      lang: 'js-collapse-lang',
    },
  },
};

// Required classnames
const classNames = {
  mobMenuTrigger: 'main-navigation__mob-trigger',
  mobMenu: 'main-navigation__mob-wrapper',
  mobMenuLangTrigger: 'main-navigation__mob-lang-trigger',
  mobMenuLangWrapper: 'main-navigation__mob-languages-wrapper',
  modifiers: {
    active: 'is-active',
    open: 'is-open',
    shown: 'is-shown',
  },
  bootstrap: {
    show: 'show',
    collapsed: 'collapsed',
  },
};

// Required dom nodes
const elements = {
  rootNode: document.querySelector('header.page-header'),
  mobMenu: {
    trigger: document.querySelector(`.${classNames.mobMenuTrigger}`),
    menu: document.querySelector(`.${classNames.mobMenu}`),
    langTrigger: document.querySelector(`.${classNames.mobMenuLangTrigger}`),
    langWrapper: document.querySelector(`.${classNames.mobMenuLangWrapper}`),
  },
  bootstrap: {
    collapse: {
      menu: document.querySelector(`#${idNames.bootstrap.collapse.menu}`),
      lang: document.querySelector(`#${idNames.bootstrap.collapse.lang}`),
    },
  },
};

/**
 * Lock scroll using Body Scroll Lock
 * @param {boolean} request True to lock (default), false to unlock
 * @returns {undefined}
 * @see {@link https://github.com/willmcpo/body-scroll-lock|GitHub}
 * @todo Refactor to use as utility
 */
function lockScroll(request = true) {
  const target1 = document.querySelector(`.${classNames.mobMenu}`);

  if (request) {
    bodyScrollLock.disableBodyScroll(target1);
    return;
  }

  bodyScrollLock.clearAllBodyScrollLocks();
}

/**
 * Open, closes or toggles (default) the mobile menu
 * @param {string} action Request to handle
 * @returns {undefined}
 */
function mobMenuToggle(request = null) {
  const { trigger, menu } = elements.mobMenu;
  const { active, open } = classNames.modifiers;

  if (request === 'open') {
    trigger.classList.add(active);
    menu.classList.add(open);
    lockScroll();
    return;
  }

  if (request === 'close') {
    trigger.classList.remove(active);
    menu.classList.remove(open);
    lockScroll(false);
    return;
  }

  trigger.classList.toggle(active);
  menu.classList.toggle(open);

  if (menu.classList.contains(open)) lockScroll();
  else {
    lockScroll(false);
    mobMenuToggleLanguages('close');
  }
}

/**
 * Toggles the Bootstrap collapse menu and languages
 * Needs to be set up manually because the design has only one trigger
 * @returns {undefined}
 */
function mobMenuToggleLanguages(request = null) {
  const { menu } = elements.mobMenu;

  if (request === 'open') {
    menu.style.overflow = 'hidden';
    return;
  }

  if (request === 'close') {
    menu.style.overflow = '';
    return;
  }

  if (menu.style.overflow === 'hidden') menu.style.overflow = '';
  else menu.style.overflow = 'hidden';
}

/**
 * Sets up a click event for the mobile menu trigger and mob menu languages btn
 * @returns {undefined}
 */
function mobMenuInit() {
  const { trigger /* , langTrigger */ } = elements.mobMenu;

  trigger.addEventListener('click', mobMenuToggle, { offset: 60 });
}

/**
 * Initializes scrolling functionality of main site header.
 * @returns {undefined}
 */
export default function initNavigation() {
  if (!scModus) {
    if (elements.rootNode) {
      const headroom = new Headroom(elements.rootNode, {
        offset: elements.rootNode.offsetHeight,
        tolerance: { up: 0, down: 60 },
      });

      headroom.init();
    }
  } else {
    console.warn('Sitecore Edit, Preview or Debug modus: Prevented Navigation Scroll functionality.');
  }

  mobMenuInit();
}

// on document ready
document.addEventListener('readystatechange', (e) => {
  if (e.target.readyState === 'complete') {
    customVhProperty();
    initNavigation();
  }
});
